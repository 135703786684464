<template>
  <section class="d-flex flex-column justify-content-between">
    <div>
      <Header/>
      <Banner/>
      <router-view></router-view>
    </div>
    <PageFooter/>
  </section>
</template>

<script>
import Header from './Components/Header'
import Banner from './Components/Banner'
import { PageFooter } from 'wize-admin'

export default {
  components: {
    Header,
    Banner,
    PageFooter
  },
  name: "WelcomeView"
}
</script>

<style scoped lang="stylus">
</style>
