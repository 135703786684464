<template>
  <form
    class="general-configuration header-config custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Header</h2>

    <section class="logo-section">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo</label>
          <p class="my-0">Sube tu logo en 272 x 50px en formato .png o .jpg</p>
        </div>

        <div class="form-field">
          <!-- <UploadImages :info="form.logo" @onChange="setLogo($event)" /> -->

          <UploadImages
            :maxFiles="1"
            :files="[form.logo]"
            @onChange="onChange($event, 'logo')"
            @remove="removeImage('logo')"
          />

          <form-error
            message="Logo es requerido"
            v-if="$v.form.logo.$error && !$v.form.logo.required"
          ></form-error>

          <form-error
            message="Las dimensiones de la imagen son incorrectas"
            v-if="form.logoErrorDimensions"
          ></form-error>
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="businessName">Logo texto</label>
        </div>

        <div class="form-field">
          <FormInput
            id="businessName"
            type="text"
            placeholder="Ingresar logo texto"
            :show-error="$v.form.name.$error && !$v.form.name.required"
            v-model="form.name"
          />

          <form-error
            message="Logo texto es requerido"
            v-if="$v.form.name.$error && !$v.form.name.required"
          ></form-error>
        </div>
      </div>
    </section>

    <section class="mail-section">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo mailing</label>
          <p class="my-0">Sube tu logo en 272 x 50px en formato .png o .jpg</p>
        </div>

        <div class="form-field">
          <UploadImages
            :maxFiles="1"
            :files="[form.mailingLogo]"
            @onChange="onChange($event, 'mailingLogo')"
            @remove="removeImage('mailingLogo')"
          />

          <form-error
            message="Logo mailing es requerido"
            v-if="$v.form.mailingLogo.$error && !$v.form.mailingLogo.required"
          ></form-error>

          <form-error
            message="Las dimensiones de la imagen son incorrectas"
            v-if="form.mailingLogoErrorDimensions"
          ></form-error>
        </div>
      </div>
    </section>

    <div class="form-group-buttons text-right align-self-end mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { GeneralConfig } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";

import { required } from "vuelidate/lib/validators";

import { FormInput, FormError, UploadImages, Util, Alert, CustomValidators } from "wize-admin";

// import UploadImages from '@/UploadImages.vue'

export default {
  data() {
    return {
      form: {
        logo: null,
        name: null,
        mailingLogo: null
      },
    };
  },
  mounted() {
    this.form = {
      ...JSON.parse(JSON.stringify(this.business)),
      logoErrorDimensions: false,
      mailingLogoErrorDimensions: false
    };
  },
  props: {
    business: Object,
  },
  components: {
    FormInput,
    FormError,
    UploadImages,
  },
  validations: {
    form: {
      logo: { required },
      name: { required },
      mailingLogo: { required },
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.form.$error || this.form.logoErrorDimensions || this.form.mailingLogoErrorDimensions) return;

      try {
        this.$store.commit("app/setLoading", true);

        let obj = {
          logoText: this.form.name,
        };

        if (this.form.updateLogo) obj.logo = this.form.updateLogo;
        if (this.form.updateMailingLogo)
          obj.mailingLogo = this.form.updateMailingLogo;

        const data = Util.getFormData(obj);

        const resp = await GeneralConfig.updateBusinessHeader(
          this.business.id,
          data
        );
        if (resp.status === "OK") {
          this.$store.commit("app/business", resp.payload);
          this.$store.commit("app/setLoading", false);
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        this.$store.commit("app/setLoading", false);
      } finally {
        this.$store.commit("app/setLoading", false);
      }
    },
    async setLogo(event) {
      this.form.updateLogo = event;

      this.form.logoErrorDimensions = await CustomValidators.fileDimensions(
        LocalConstants.ConfigLogoDimensions,
        this.form.updateLogo
      );
    },
    async setMailingLogo(event) {
      this.form.updateMailingLogo = event

      this.form.mailingLogoErrorDimensions = await CustomValidators.fileDimensions(
        LocalConstants.ConfigLogoDimensions,
        this.form.updateMailingLogo
      )
    },
    async onChange(files, type) {
      try {
        if(type === 'logo') {
          this.form.updateLogo = files[0].file
          this.form.logo = files[0].file
        } else {
          this.form.updateMailingLogo = files[0].file
          this.form.mailingLogo = files[0].file
        }
      } catch (error) {
        console.error(error);
      }
    },
    removeImage(type) {
      if(type === 'logo') {
        this.form.logo = null
      } else {
        this.form.mailingLogo = null
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/general-configuration.styl"
</style>
