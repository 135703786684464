<template>
  <section class="dashboard-container">
    <h1 class="dashboard-title">{{ business.tradeName }}</h1>
    <div class="dashboard-card"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      business: "app/getBusiness",
    }),
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/Dashboard.styl"
</style>
