<template>
  <b-button
      class="
      business-card
      d-block
      animate__animated animate__fadeIn
    "
      @click="selectBusiness"
  >
    <div class="h-100 d-flex flex-column justify-content-between">
      <section class="text-left">
        <span class="title text-dark">{{ business.tradeName }}</span>
      </section>

      <div class="w-100 d-flex justify-content-between align-items-end">
        <div class="text-left">
          <span class="subtitle text-dark">{{ business.name }}</span>
          <span class="subtitle d-block">{{ business.identifier }}</span>
        </div>

        <BusinessIcon class="fill-primary" />
      </div>
    </div>
  </b-button>
</template>

<script>
import { BusinessIcon } from 'wize-admin'

export default {
  name: "BusinessCard",

  data() {
    return {};
  },
  components: {
    BusinessIcon
  },
  methods: {
    async selectBusiness(){
      // TODO Put loading here
      this.$store.commit('app/business', this.business);
      this.$router.push({name: 'dashboard'})
    }
  },
  props: {
    business: Object,
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/card';
</style>
