<template>
  <section class="businesses-page d-flex flex-column justify-content-between">
      <div>
        <BusinessesHeader />

        <BusinessesBanner />

        <BusinessesList />
      </div>

      <PageFooter />
  </section>
</template>

<script>
import BusinessesHeader from '../Components/Header'
import BusinessesBanner from '../Components/Banner'
import BusinessesList from '../Components/BusinessesList'
import { PageFooter } from 'wize-admin'

export default {
  components: {
    BusinessesHeader,
    BusinessesBanner,
    BusinessesList,
    PageFooter
  }
}
</script>

<style lang="stylus" scoped>
@import "../Styles/businesses-page.styl"
</style>