<template>
  <div id="container" class="general-config page-container">
    <div class="page-title-container">
      <h1 class="page-title">Configuración</h1>
    </div>

    <section
      class="
        tabs-container
        d-flex
        flex-column flex-md-row
        justify-content-center
      "
    >
      <div
        class="tabs-sidebar d-flex flex-column"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <router-link
          :to="{ name: $route.name, query: { tab: item.tab } }"
          v-for="item in tabs"
          :key="item.id"
          class="tab-link text-decoration-none"
          :class="{
            active:
              item.tab === $route.query.tab ||
              (item.id == 1 && !$route.query.tab),
          }"
          >{{ item.text }}</router-link
        >
      </div>

      <div
        class="tabs-content"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <GeneralConfig :business="business" v-if="isGeneral" />

        <HeaderConfig :business="business" v-if="isHeader" />

        <ThemeColorConfig :business="business" v-if="isThemeColor" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { BusinessService } from '@/core/services'

import GeneralConfig from "../Components/GeneralConfig";
import HeaderConfig from "../Components/HeaderConfig";
import ThemeColorConfig from "../Components/ThemeColorConfig";

export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          text: "General",
          tab: "general",
        },
        {
          id: 2,
          text: "Header",
          tab: "header",
        },
        {
          id: 3,
          text: "Color de tema",
          tab: "theme",
        },
      ],
    };
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: document.querySelector("#container"),
      updateHistory: false,
      offset: -200,
    });

    this.getBusinessInfo();
  },
  components: {
    GeneralConfig,
    HeaderConfig,
    ThemeColorConfig,
  },
  methods: {
    async getBusinessInfo() {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await BusinessService.getBusiness(this.business.id);
        let data = {...response.payload}
        this.$store.commit('app/business', data)
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    ...mapGetters({
      business: "app/getBusiness",
    }),
    isGeneral() {
      return this.$route.query.tab == "general" || !this.$route.query.tab;
    },
    isHeader() {
      return this.$route.query.tab == "header";
    },
    isThemeColor() {
      return this.$route.query.tab == "theme";
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-config-page.styl';
</style>
