import {http} from '@/http'

export default {
    updateGeneralInfo(businessId, params) {
        return http.put(`/api/v1/business/${businessId}/general`, null, {params})
    },
    updateBusinessColor(businessId, params) {
        return http.put(`/api/v1/business/${businessId}/color`, null, { params })
    },
    updateBusinessHeader(businessId, data) {
        return http.put(`/api/v1/business/${businessId}/header`, data)
    }
}