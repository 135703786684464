<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Reclamaciones</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar reclamos</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <section class="d-flex align-items-center">
          <button type="button" class="button-tooltip" v-b-tooltip.hover title="Recargar" @click="loadData">
            <RefreshTableIcon />
          </button>

          <SearchBar
            placeholder="Buscar por ID de reporte, nombres o apellidos."
            @search="onSearch"
            @input="search = $event"
          />
        </section>
      </div>

      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Fecha <TableOrderIcon class="ml-2" /></th>
              <th>ID de reporte</th>
              <th>
                Tipo de <br />
                reporte
              </th>
              <th>Nombre y apellidos</th>
              <th>Celular</th>
              <th>
                Menor <br />
                de edad
              </th>
              <th>
                Bien <br />
                contratado
              </th>
              <th>Monto</th>
              <th>Fecha de <br> respuesta</th>
              <th>Link</th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center" v-local-date="o.createdAt"></td>
              <td class="text-center">{{ o.refNumber }}</td>
              <td class="text-center">{{ getReportType(o.type).label }}</td>
              <td class="text-center">
                {{ `${o.firstName} ${o.lastName}` }}
              </td>
              <td class="text-center">{{ o.mobile }}</td>
              <td class="text-center">{{ o.isUnderAge ? "Sí" : "No" }}</td>
              <td class="text-center">{{ getWeelType(o.wellType).label }}</td>
              <td class="text-center" v-decimal:pen="o.wellAmount"></td>
              <td class="text-center" v-local-date="o.respondedAt"></td>
              <td class="text-center">
                <a :href="`${webUrl}/${business.slug}/${o.code}`" target="_blank">
                  <LinkIcon class="fill-blue link-icon" />
                </a>
              </td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="text-center d_option pr-md-3">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      @click="
                        $router.push({
                          name: 'complaints-details',
                          params: { complaintId: o.code },
                        })
                      "
                      text="Ver datos"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment"

import { ComplaintsService } from "@/core/services";
import { Constants as LocalConstants } from '@/core/utils'

import {
  Alert,
  Constants,
  Util,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  RefreshTableIcon,
  FiltersIcon,
  DeleteFilterIcon,
  TableOrderIcon,
  FilterMenu,
  FilterMixin,
  LinkIcon
} from "wize-admin";
import { mapGetters } from 'vuex';

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    RefreshTableIcon,
    FiltersIcon,
    DeleteFilterIcon,
    TableOrderIcon,
    LinkIcon
  },
  mixins: [PaginationMixin, FilterMixin],
  data() {
    return {
      webUrl: process.env.VUE_APP_WEB_URL,
      list: [],
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ComplaintsStatus.PENDING,
            LocalConstants.ComplaintsStatus.RESPONDED,
          ],
        },
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE
        },
        {
          label: "Tipo de reporte",
          key: "type",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ReportTypeValues.CLAIM,
            LocalConstants.ReportTypeValues.COMPLAINT,
          ],
        },
        {
          label: "Menor de edad",
          key: "isUnderAge",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.IsUnderage.YES,
            LocalConstants.IsUnderage.NOT
          ],
        },
        {
          label: "Bien contratado",
          key: "wellType",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.WellTypeValues.PRODUCT,
            LocalConstants.WellTypeValues.SERVICE
          ],
        }
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        let filters = JSON.parse(JSON.stringify(this.filters))

        for(const filter in filters) {
          if(filters[filter].selectedDate) {
            filters.startDate = moment(filters[filter].startDate).format("YYYY-MM-DD")
            filters.endDate = moment(filters[filter].endDate).format("YYYY-MM-DD")

            delete filters[filter]
          }
        }

        const params = {page: this.page - 1., size: this.pageSize, sort: 'createdAt,desc'};
        const data = {search: this.search, ...filters};

        const response = await ComplaintsService.list(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;

      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getReportType(key) {
      return Util.searchValue(key, LocalConstants.ReportTypeValues);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ComplaintsStatus);
    },
    getWeelType(key) {
      return Util.searchValue(key, LocalConstants.WellTypeValues);
    }
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    ...mapGetters({
      business: 'app/getBusiness'
    })
  }
};
</script>

<style lang="stylus" scoped></style>
