import WelcomeView from "../WelcomeView.vue";
import WelcomePage from "../Pages/WelcomePage.vue";

const routes = [
  {
    path: '/welcome',
    component: WelcomeView,
    children: [
      {
        path: '',
        name: 'welcome',
        component: WelcomePage,
      },
    ]
  }
]

export default routes
