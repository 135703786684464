import {http} from '@/http'

export default {
  list() {
    return http.get('api/v1/business/all')
  },
  getBusiness(businessId) {
    return http.get(`/api/v1/business/${businessId}`)
  },
  getAvailableDate() {
    return http.get('api/v1/business/available/date')
  },
  getLastClosedDate() {
    return http.get('api/v1/business/last/closed/date')
  },
  getStartDate() {
    return http.get('api/v1/business/startdate')
  },
  updateData(businessId) {
    return http.put(`/api/v1/business/${businessId}`)
  },
  deleteData(params) {
    return http.delete('api/v1/business/data', {params})
  },
}
