<template>
  <header class="admin-header">
    <div
      class="container d-flex justify-content-between align-items-center py-0"
    >
      <div>
        <router-link
          to="/businesses"
          class="d-flex align-items-center text-decoration-none"
        >
          <img
            src="@/core/assets/images/businesses/logo.svg"
            alt="Logo panel de administración"
            class="logo"
          />
          <!-- TODO replace app icon -->
          <h2 class="title text-white font-weight-bold text-uppercase my-0">
            ZET
          </h2>
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <UserDropdown :user="user" @logout="logout()" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import { UserDropdown } from "wize-admin";

export default {
  name: "AdminHeader",
  components: {
    UserDropdown,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      business: "app/getBusiness",
      businesses: "app/getBusinesses",
    }),
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout");
      },
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/header";
</style>
