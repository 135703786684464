import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/api/v1/complaint/list', data, {params})
  },
  get(params) {
    return http.get(`/api/v1/complaint`, {params})
  },
  respond(data, params) {
    return http.put(`/api/v1/complaint`, data, {params})
  }
}