<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <page-sidebar
        title="ZET"
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
      >
        <template #list-items>
          <!-- Portada -->
          <router-link
            :to="{ name: 'dashboard' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <HomeIcon class="sidebar-icon" />

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>

          <!-- Reclamaciones -->

          <router-link
            :to="{ name: 'complaints' }"
            tag="li"
            active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'complaint-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <MessageIcon class="sidebar-icon" />

              <span class="sidebar-item-text">Reclamaciones</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'complaint-item'"
                text="Reclamaciones"
              />
            </div>
          </router-link>
        </template>

        <template #footer>
          <section class="page-sidebar-footer">
            <router-link
              :to="{ name: 'general-configuration' }"
              tag="li"
              exact-active-class="active"
              class="d-flex sidebar-item sidebar-item-footer"
              @mouseover.native="tooltipType = 'general-configuration-item'"
              @mouseleave.native="tooltipType = null"
            >
              <div class="d-flex align-items-center">
                <ConfigIcon class="sidebar-icon" />

                <span class="sidebar-item-text">Configuración general</span>

                <CustomTooltip
                  custom-class="tooltip-sidebar"
                  v-if="!isMenuOpen && tooltipType === 'general-configuration-item'"
                  text="Configuración general"
                />
              </div>
            </router-link>

            <div class="d-flex justify-content-between align-items-center sidebar-item sidebar-item-footer" v-if="isMenuOpen">
              <span class="plan">Plan gratuito</span>

              <router-link to="/" class="text-white plan-link">Modificar</router-link>
            </div>
          </section>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import PageHeader from "./PageHeader";

import {
  PageSidebar,
  CustomTooltip,
  HomeIcon,
  MessageIcon,
  PageFooter,
  ConfigIcon,
} from "wize-admin";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    HomeIcon,
    MessageIcon,
    ConfigIcon
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
    }),
  },
  methods: {
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
};
</script>

<style scoped lang="stylus">
</style>
