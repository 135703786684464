<template>
  <form
    class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Color de tema</h2>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container mb-2 mb-md-0">
        <label for="name">Color principal</label>
        <p class="my-0">Selecione o ingrese el color en código hexadecimal. Este color se mostrará en el portal web.</p>
      </div>

      <div class="form-field">
        <FormColorPicker v-model="form.primaryColor" />
        <form-error
          message="Color principal es requerido"
          v-if="$v.form.primaryColor.$error && !$v.form.primaryColor.required"
        ></form-error>

        <form-error
          message="Se requiere un valor exadecimal"
          v-if="!isHexadecimal"
        ></form-error>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { GeneralConfig } from "@/core/services";

import { required } from "vuelidate/lib/validators";

import { FormColorPicker, FormError, Alert } from "wize-admin";

export default {
  data() {
    return {
      form: {
        primaryColor: "#000000",
      },
    };
  },
  props: {
    business: Object,
  },
  mounted() {
    this.form = this.business;
  },
  components: {
    FormError,
    FormColorPicker,
  },
  validations: {
    form: {
      primaryColor: { required },
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error || !this.isHexadecimal) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = {
          primaryColor: this.form.primaryColor,
        };

        const resp = await GeneralConfig.updateBusinessColor(
          this.business.id,
          params
        );

        if(resp.status === "OK") {
          this.$store.commit("app/business", resp.payload);
          this.$store.commit("app/setLoading", false)
          Alert.success("Se ha realizado la actualización correctamente")
        }
      } catch (e) {
        this.$store.commit("app/setLoading", false)
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    isHexadecimal: function() {
      const pattern= /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/
      return pattern.test(this.form.primaryColor)
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/general-configuration.styl"
</style>
