<template>
  <section id="container" class="complaint-details">
    <div class="banner">
      <h1 class="title">Reclamo N° {{ complaint.refNumber }}</h1>
    </div>

    <div class="card-full-page">
      <section class="section">
        <h2 class="subtitle my-0">Información del cliente</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Tipo de documento</label>
            <p class="text">
              {{ getIdentifierType(complaint.identifierType).label }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Número de documento</label>
            <p class="text">{{ complaint.identifier }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Nombres</label>
            <p class="text">{{ complaint.firstName }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Apellidos</label>
            <p class="text">{{ complaint.lastName }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Celular</label>
            <p class="text">{{ complaint.mobile }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Correo electrónico</label>
            <p class="text">{{ complaint.email }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Dirección</label>
            <p class="text">{{ complaint.address }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Departamento</label>
            <p class="text">{{ complaint.department }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Provincia</label>
            <p class="text">{{ complaint.province }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Distrito</label>
            <p class="text">{{ complaint.district }}</p>
          </div>
        </div>

        <!-- Menor de edad -->
        <template v-if="complaint.isUnderAge">
          <div class="row">
            <div class="col-12">
              <h2 class="subtitle secondary mb-0 mx-0 px-0">
                Información del apoderado
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="" class="label">Tipo de documento</label>
              <p class="text">
                {{ getIdentifierType(complaint.guardianIdentifierType).label }}
              </p>
            </div>

            <div class="col-md-6">
              <label for="" class="label">Número de documento</label>
              <p class="text">{{ complaint.guardianIdentifier }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="" class="label">Nombres</label>
              <p class="text">{{ complaint.guardianFirstName }}</p>
            </div>

            <div class="col-md-6">
              <label for="" class="label">Apellidos</label>
              <p class="text">{{ complaint.guardianLastName }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="" class="label">Celular</label>
              <p class="text">{{ complaint.guardianMobile }}</p>
            </div>

            <div class="col-md-6">
              <label for="" class="label">Correo electrónico</label>
              <p class="text">{{ complaint.guardianEmail }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label for="" class="label">Dirección</label>
              <p class="text">{{ complaint.guardianAddress }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="" class="label">Departamento</label>
              <p class="text">{{ complaint.guardianDepartment }}</p>
            </div>

            <div class="col-md-6">
              <label for="" class="label">Provincia</label>
              <p class="text">{{ complaint.guardianProvince }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="" class="label">Distrito</label>
              <p class="text">{{ complaint.guardianDistrict }}</p>
            </div>
          </div>
        </template>
      </section>

      <section class="section">
        <h2 class="subtitle">Información del bien contratado</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Bien contratado</label>

            <p class="text">
              {{ getWeelType(complaint.wellType).label }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Monto del reclamo en soles</label>

            <p class="text" v-decimal:pen="complaint.wellAmount"></p>
          </div>

          <div class="col-12">
            <label for="" class="label">Descripción</label>

            <p class="text">
              {{ complaint.wellDescription }}
            </p>
          </div>
        </div>
      </section>

      <section class="section">
        <h2 class="subtitle">Información del detalle del reclamo</h2>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Tipo de reporte</label>

            <p class="text">
              {{ getReportType(complaint.type).label }}
            </p>
          </div>

          <div class="col-12">
            <label for="" class="label">Descripción</label>

            <p class="text">
              {{ complaint.description }}
            </p>
          </div>
        </div>
      </section>

      <section class="section">
        <h2 class="subtitle">Información de archivos adjuntos</h2>

        <div class="row">
          <div class="col-12">
            <label for="" class="label d-block mb-2">Descargar archivos</label>

            <div
              class="d-flex flex-column align-items-start"
              v-if="complaint.attachments && complaint.attachments.length"
            >
              <a
                href=""
                class="attachement"
                v-for="item in complaint.attachments"
                :key="item.id"
                @click.prevent="downloadFile(item)"
              >
                <DownloadFileIcon />
                <span class="d-inline-block ml-2">{{ item.name }}</span>
              </a>
            </div>

            <InfoBadge
              text="El cliente no adjuntó archivos."
              class="alert-attachments mt-2"
              v-else
            >
              <template v-slot:icon>
                <WarningAlertIcon class="mr-2 alert-icon" />
              </template>
            </InfoBadge>
          </div>
        </div>
      </section>

      <b-form
        class="section custom-form border-0 mb-0 pb-0"
        @submit.prevent="openSubmitProcess()"
      >
        <h2 class="subtitle">
          Observaciones y acciones adoptadas por el proveedor
        </h2>

        <div class="row">
          <div class="col-12">
            <label for="observations" class="label d-block">{{
              complaint.respondedAt
                ? "Descripción"
                : "Ingrese las acciones adaptadas"
            }}</label>

            <div
              class="form-group mt-2 mb-0"
              v-if="complaint.status == ComplaintsStatus.PENDING.key"
            >
              <textarea
                id="observations"
                placeholder="Escriba aquí"
                class="custom-form-control"
                v-model="form.response"
              ></textarea>
              <FormError
                v-if="$v.form.response.$error && !$v.form.response.required"
                message="La respuesta es requerida"
              />
            </div>

            <p class="text" v-else>
              {{ complaint.response }}
              <InfoBadge
                text="Sin acciones tomadas por el proveedor."
                class="alert-response mt-2"
                v-if="!complaint.response"
              >
                <template v-slot:icon>
                  <WarningAlertIcon class="mr-2 alert-icon" />
                </template>
              </InfoBadge>
            </p>

            <div class="form-group-buttons text-right mb-0 pb-0">
              <button @click="cancel" class="button button-light mr-1">
                {{
                  complaint.status === ComplaintsStatus.PENDING.key
                    ? "Cancelar"
                    : "Volver"
                }}
              </button>

              <button
                class="button button-primary"
                type="submit"
                v-if="!complaint.respondedAt"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </section>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import { requiredIf } from "vuelidate/lib/validators";
import { ComplaintsService } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";

import {
  Constants,
  Util,
  DownloadFileIcon,
  ConfirmationModal,
  ConfirmationModalMixin,
  InfoBadge,
  WarningAlertIcon,
  Alert,
  FormError,
} from "wize-admin";

export default {
  name: "DashboardPage",
  mixins: [ConfirmationModalMixin],
  components: {
    DownloadFileIcon,
    ConfirmationModal,
    InfoBadge,
    WarningAlertIcon,
    FormError,
  },
  data() {
    return {
      complaint: {},
      ComplaintsStatus: LocalConstants.ComplaintsStatus,
      form: {
        response: null,
      },
    };
  },
  created() {
    if (this.$route.params.complaintId)
      this.load(this.$route.params.complaintId);
  },
  validations: {
    form: {
      response: {
        required: requiredIf(function () {
          return this.complaint.type == 2 ? true : false;
        }),
      },
    },
  },
  methods: {
    async load() {
      try {
        this.$store.dispatch("app/loading", true);
        const params = { code: this.$route.params.complaintId };
        const response = await ComplaintsService.get(params);
        this.complaint = { ...response.payload };
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getWeelType(key) {
      return Util.searchValue(key, LocalConstants.WellTypeValues);
    },
    getReportType(key) {
      return Util.searchValue(key, LocalConstants.ReportTypeValues);
    },
    confirmModal() {
      this.closeConfirmationModal();
      this.submit();
    },
    openSubmitProcess() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      if (this.complaint.respondedAt) return;
      this.openConfirmationModal({
        title: "Marcar como atendido",
        description:
          "¿Está seguro de marcar como atendido el reclamo?. Esta acción no puede ser revertida.",
        acceptText: "Guardar",
        action: "Cancelar",
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const params = { code: this.$route.params.complaintId };
        const data = {
          response: this.form.response,
        };

        await ComplaintsService.respond(data, params);

        this.load();

        this.$smoothScroll({
          scrollTo: document.querySelector("#container"),
          updateHistory: false,
        });

        Alert.success("Se ha realizado la actualización correctamente");

        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async df(url, filename) {
      const response = await axios.get(url, { responseType: "blob" });
      let headers = response.headers;
      const blob = new Blob([response.data], { type: headers["content-type"] });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadFile(item) {
      this.df(item.url, item.name);
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.complaintId)
        this.load(this.$route.params.complaintId);
    },
  },
  computed: {
    ...mapGetters({
      business: "app/getBusiness",
    }),
  },
};
</script>

<style lang="stylus">
@import "../Styles/complaint-details.styl"
</style>
