const Errors = {
  SERVER_UNAVAILABLE: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const ComplaintsStatus = {
  PENDING: { key: 1, label: 'PENDIENTE', color: 'badge-gray' },
  RESPONDED: { key: 2, label: 'ATENDIDO', color: 'badge-green' }
}

const WellTypeValues = {
  PRODUCT: { key: 1, label: 'PRODUCTO' },
  SERVICE: { key: 2, label: 'SERVICIO' }
}

const ReportTypeValues = {
  COMPLAINT: { key: 1, label: 'QUEJA' },
  CLAIM: { key: 2, label: 'RECLAMO' }
}

const IsUnderage = {
  NOT: { key: 0, label: 'NO' },
  YES: { key: 1, label: 'SI' }
}

const ConfigLogoDimensions = {
  width: 272,
  height: 90
}


export default {
  Errors,
  ComplaintsStatus,
  WellTypeValues,
  ReportTypeValues,
  IsUnderage,
  ConfigLogoDimensions
}
