<template>
  <b-card
    tag="article"
    no-body
    style="max-width: 640px"
    class="custom-card supplier-card"
  >
    <b-card-header
      header-tag="section"
      class="custom-card-header supplier-card-header d-flex align-items-center"
    >
      <h3>Datos de la nueva empresa</h3>
    </b-card-header>
    <b-card-body class="custom-card-body supplier-card-body">
      <div class="alert-container">
        <InfoBadge
          text="Todos los campos son obligatorios y serán comprobados por nuestro equipo."
          class="alert-data"
        >
          <template slot="icon">
            <WarningAlertIcon class="mr-2 alert-icon" />
          </template>
        </InfoBadge>
      </div>
      <form class="custom-form supplier-form" @submit.prevent="()=>{}">
        <div class="form-group">
          <label for="name">Nombre comercial *</label>
          <FormInput
            id="name"
            type="text"
            maxlength="15"
            placeholder="Ingresar nombre comercial"
            :show-error="$v.form.name.$error && !$v.form.name.required"
            v-model="form.name"
          />

          <form-error
            message="Nombre comercial es requerido"
            v-if="$v.form.name.$error && !$v.form.name.required"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="businessName">Razón social *</label>
          <FormInput
            id="businessName"
            type="text"
            maxlength="15"
            placeholder="Ingresar razón social *"
            :show-error="
              $v.form.businessName.$error && !$v.form.businessName.required
            "
            v-model="form.businessName"
          />

          <form-error
            message="Razón social es requerido"
            v-if="$v.form.businessName.$error && !$v.form.businessName.required"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="ruc">R.U.C. *</label>
          <FormInput
            id="ruc"
            type="text"
            maxlength="15"
            placeholder="Ingresar RUC"
            :show-error="$v.form.ruc.$error && !$v.form.ruc.required"
            v-model="form.ruc"
          />

          <form-error
            message="RUC es requerido"
            v-if="$v.form.ruc.$error && !$v.form.ruc.required"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="address">Dirección *</label>
          <FormInput
            id="address"
            type="text"
            maxlength="15"
            placeholder="Ingresar dirección"
            :show-error="$v.form.address.$error && !$v.form.address.required"
            v-model="form.address"
          />

          <form-error
            message="Dirección es requerida"
            v-if="$v.form.address.$error && !$v.form.address.required"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="contactPerson">Persona de contacto *</label>
          <FormInput
            id="contactPerson"
            type="text"
            maxlength="15"
            placeholder="Ingresar persona de contacto"
            :show-error="
              $v.form.contactPerson.$error && !$v.form.contactPerson.required
            "
            v-model="form.contactPerson"
          />

          <form-error
            message="Persona de contacto es requerido"
            v-if="
              $v.form.contactPerson.$error && !$v.form.contactPerson.required
            "
          ></form-error>
        </div>

        <div class="form-group">
          <label for="mobile">Celular *</label>
          <FormInput
            id="mobile"
            type="text"
            maxlength="15"
            placeholder="Ingresar celular"
            :show-error="$v.form.mobile.$error && !$v.form.mobile.required"
            v-model="form.mobile"
          />

          <form-error
            message="Celular es requerido"
            v-if="$v.form.mobile.$error && !$v.form.mobile.required"
          ></form-error>
          <form-error
            message="Ingresa un celular válido de 9 dígitos"
            v-if="$v.form.mobile.$error && !$v.form.mobile.mobile"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="email">Correo electrónico *</label>

          <FormInput
            id="email"
            type="text"
            maxlength="15"
            placeholder="Ingresar correo electrónico"
            :show-error="$v.form.mobile.$error && !$v.form.mobile.required"
            v-model="form.email"
          />

          <form-error
            message="Correo electrónico es requerido"
            v-if="$v.form.email.$error && !$v.form.email.required"
          ></form-error>
          <form-error
            message="Ingresa un correo electrónico válido"
            v-if="$v.form.email.$error && !$v.form.email.email"
          ></form-error>
        </div>

        <div class="form-group">
          <label for="webPage">Página web *</label>
          <FormInput
            id="webPage"
            type="text"
            maxlength="15"
            placeholder="Ingresar página web es requerida"
            :show-error="$v.form.webPage.$error && !$v.form.webPage.required"
            v-model="form.webPage"
          />

          <form-error
            message="Página web"
            v-if="$v.form.webPage.$error && !$v.form.webPage.required"
          ></form-error>
        </div>

        <div class="form-group-buttons text-right">
          <button @click="cancel" class="button button-light">Cancelar</button>
          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </b-card-body>
  </b-card>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import {
  InfoBadge,
  FormError,
  CustomValidators,
  FormInput,
  WarningAlertIcon,
} from "wize-admin";

export default {
  name: "SupplierForm",
  components: {
    InfoBadge,
    FormError,
    FormInput,
    WarningAlertIcon,
  },
  data() {
    return {
      form: {
        name: null,
        businessName: null,
        mobile: null,
        email: null,
        ruc: null,
        address: null,
        contactPerson: null,
        webPage: null
      },
    };
  },
  validations: {
    form: {
      mobile: { required, mobile: CustomValidators.mobile },
      email: { required, email },
      name: { required },
      businessName: { required },
      ruc: { required },
      address: { required },
      contactPerson: { required },
      webPage: { required }
    },
  },
  methods: {
    cancel() {
      this.$store
        .dispatch("auth/logout");
    },
  },
};
</script>

<style lang="stylus">
@import '../Styles/welcome-form';
</style>
