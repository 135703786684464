<template>
  <form
    class="
      general-configuration
      custom-form
      h-100
      d-flex
      flex-column
      animate__animated animate__fadeIn
    "
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Información general del negocio</h2>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container mb-2 mb-md-0">
        <label for="tradeName">Nombre comercial</label>
        <p class="my-0">
          Este nombre se utilizará para crear su url personalizada por ejemplo:
          zet.wize.pe/nombrecomercial <br />
          * No incluir SAC, EIRL, entre otros
        </p>
      </div>

      <div class="form-field">
        <FormInput
          id="tradeName"
          type="text"
          placeholder="Ingresar nombre comercial"
          :show-error="$v.form.tradeName.$error && !$v.form.tradeName.required"
          v-model="form.tradeName"
        />

        <form-error
          message="Nombre comercial es requerido"
          v-if="$v.form.tradeName.$error && !$v.form.tradeName.required"
        ></form-error>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="slug">Slug</label>
      </div>

      <div class="form-field d-flex align-items-center">
        <div class="w-100">
          <FormInput
            id="slug"
            type="text"
            placeholder="Ingresar slug"
            :show-error="$v.form.slug.$error && !$v.form.slug.required"
            v-model="form.slug"
          />

          <form-error
            message="Slug es requerido"
            v-if="$v.form.slug.$error && !$v.form.slug.required"
          ></form-error>
        </div>

        <a :href="form.slugUrl" target="_blank">
          <LinkIcon class="fill-primary ml-2" />
        </a>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="name">Razón social</label>
      </div>

      <div class="form-field">
        <FormInput
          id="name"
          type="text"
          placeholder="Ingresar Razón social"
          :show-error="$v.form.name.$error && !$v.form.name.required"
          v-model="form.name"
        />

        <form-error
          message="Razón social es requerido"
          v-if="$v.form.name.$error && !$v.form.name.required"
        ></form-error>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="identifier">RUC</label>
      </div>

      <div class="form-field">
        <FormInput
          id="identifier"
          type="text"
          maxlength="11"
          placeholder="Ingresar RUC"
          :show-error="
            $v.form.identifier.$error && !$v.form.identifier.required
          "
          v-model="form.identifier"
        />

        <form-error
          message="R.U.C. es requerido"
          v-if="$v.form.identifier.$error && !$v.form.identifier.required"
        ></form-error>
        <FormError
          v-if="$v.form.identifier.$error && !$v.form.identifier.numeric"
          message="No es un número de documento válido"
        />
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="address">Dirección</label>
      </div>

      <div class="form-field">
        <FormInput
          id="address"
          type="text"
          placeholder="Ingresar dirección"
          :show-error="$v.form.address.$error && !$v.form.address.required"
          v-model="form.address"
        />

        <form-error
          message="Dirección es requerida"
          v-if="$v.form.address.$error && !$v.form.address.required"
        ></form-error>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="contact">Persona de contacto</label>
      </div>

      <div class="form-field">
        <FormInput
          id="contact"
          type="text"
          placeholder="Ingresar persona de contacto"
          :show-error="$v.form.contact.$error && !$v.form.contact.required"
          v-model="form.contact"
        />

        <form-error
          message="Persona de contacto es requerido"
          v-if="$v.form.contact.$error && !$v.form.contact.required"
        ></form-error>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="email">Correo electrónico</label>
      </div>

      <div class="form-field">
        <FormInput
          id="email"
          type="text"
          placeholder="Ingresar correo electrónico"
          :show-error="$v.form.email.$error"
          v-model="form.email"
        />

        <form-error
          message="Correo electrónico es requerido"
          v-if="$v.form.email.$error && !$v.form.email.required"
        ></form-error>
        <form-error
          message="Ingrese un correo electrónico válido"
          v-if="$v.form.email.$error && !$v.form.email.email"
        ></form-error>
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="mobile">Celular</label>
      </div>

      <div class="form-field">
        <FormInput
          id="mobile"
          type="text"
          maxlength="9"
          placeholder="Ingresar celular"
          :show-error="$v.form.mobile.$error && !$v.form.mobile.required"
          v-model="form.mobile"
        />

        <FormError
          v-if="$v.form.mobile.$error && !$v.form.mobile.required"
          message="Celular es requerido"
        />
        <FormError
          v-if="$v.form.mobile.$error && !$v.form.mobile.numeric"
          message="Celular debe tener solo valores numéricos"
        />
        <FormError
          v-if="$v.form.mobile.$error && !$v.form.mobile.mobile"
          message="No es un celular válido"
        />
      </div>
    </div>

    <div class="form-group d-flex flex-column flex-md-row">
      <div class="label-container">
        <label for="website">Página web</label>
      </div>

      <div class="form-field">
        <FormInput
          id="website"
          type="text"
          placeholder="Ingresar página web"
          :show-error="$v.form.website.$error && !$v.form.website.required"
          v-model="form.website"
        />

        <form-error
          message="Página web es requerido"
          v-if="$v.form.website.$error && !$v.form.website.required"
        ></form-error>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { GeneralConfig } from "@/core/services";
import { email, numeric, required } from "vuelidate/lib/validators";

import {
  Alert,
  CustomValidators,
  FormError,
  FormInput,
  LinkIcon,
} from "wize-admin";

export default {
  data() {
    return {
      webUrl: process.env.VUE_APP_WEB_URL,
      form: {
        tradeName: null,
        name: null,
        identifier: null,
        address: null,
        contact: null,
        email: null,
        mobile: null,
        website: null,
      },
    };
  },
  mounted() {
    this.loadBusiness();
  },
  props: {
    business: Object,
  },
  components: {
    FormInput,
    FormError,
    LinkIcon,
  },
  validations: {
    form: {
      tradeName: { required },
      name: { required },
      slug: { required },
      identifier: { required, numeric },
      address: { required },
      contact: { required },
      email: { required, email },
      mobile: { required, numeric, mobile: CustomValidators.mobile },
      website: { required },
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = {
          ...this.form,
        };
        const resp = await GeneralConfig.updateGeneralInfo(
          this.form.id,
          params
        );

        if (resp.status === "OK") {
          await this.$store.commit("app/business", resp.payload);
          this.loadBusiness();
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    loadBusiness() {
      this.form = JSON.parse(JSON.stringify(this.business));
      this.form.slugUrl = `${this.webUrl}/${this.form.slug}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
