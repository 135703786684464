import ComplaintsView from "../ComplaintsView.vue";
import ComplaintsPage from "../Pages/ComplaintsPage.vue";
import ComplaintDetails from "../Pages/ComplaintDetails.vue";

const routes = [
  {
    path: '/complaints',
    component: ComplaintsView,
    children: [
      {
        path: '',
        name: 'complaints',
        component: ComplaintsPage
      },
      {
        path: ':complaintId',
        name: 'complaints-details',
        component: ComplaintDetails
      }
    ]
  }
]

export default routes
