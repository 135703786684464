<template>
  <nav class="col-12 navbar nav-header py-0">
    <div class="col-12 d-flex align-items-center" :class="(business) ? 'justify-content-between': 'justify-content-end'">
      <dropdown-basic
        class="businesses-dropdown"
        :text="business.tradeName"
        :left="true"
        v-if="business"
      >
        <template #dropdown-content>
          <!-- Título -->
          <b-dropdown-item
            class="dropdown-title"
            @click="$router.push({ name: 'businesses' })"
            >Ver todas las marcas</b-dropdown-item
          >

          <b-dropdown-item
            v-for="i in businesses"
            :key="i.id"
            @click="changeBusiness(i)"
            v-show="i.id !== business.id"
            >{{ i.tradeName }}
          </b-dropdown-item>
        </template>
      </dropdown-basic>

      <UserDropdown :user="user" @logout="logout()" />
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import { DropdownBasic, UserDropdown } from "wize-admin";

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: []
    };
  },
  components: {
    DropdownBasic,
    UserDropdown,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      business: "app/getBusiness",
      businesses: "app/getBusinesses",
    }),
  },
  methods: {
    async changeBusiness(business) {
      this.$store.commit("app/business", business);

      if (this.$route.name !== "dashboard")
        this.$router.push({ name: "dashboard" });
    },
    logout() {
      this.$store.dispatch('auth/logout');
    }
  },
  mounted: function () {},
};
</script>

<style scoped lang="stylus">
</style>
